import axios from "axios";
import format from "date-fns/format";
import { marked } from "marked";
import Noty, { Type } from "noty";

import { components as OpenAPI } from "@/autogen/openapi";
import Urls from "@/autogen/urls";
import { Email } from "@/types/email";
import { Newsletter } from "@/types/newsletter";
import { Context } from "@/types/preview";

const debounce = function <U>(
  func: (...args: any[]) => Promise<U> | U,
  wait: number,
  immediate: boolean = false
): (...args: any[]) => void {
  var timeout: ReturnType<typeof setTimeout> | null;

  return function executedFunction() {
    // @ts-ignore
    var context = <any>this;
    var args = arguments;

    var callNow = immediate && !timeout;

    var later = function () {
      timeout = null;
      if (!callNow) func.apply(context, Array.from(args));
    };

    if (timeout) {
      // @ts-ignore
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, Array.from(args));
  };
};

const notify = function (text: string, type: Type) {
  new Noty({
    text,
    type,
    progressBar: false,
  }).show();
};

export type RenderedMarkdown = Record<Context, string> & { error?: string };

// Needs to map to the keys of `TRANSACTIONAL_EMAIL_KEY_TO_TRANSACTIONAL_EMAIL`.
export type TransactionalEmail =
  | "confirmation"
  | "gift"
  | "automation_notification";

const asynchronouslyRenderMarkdown = async (
  subject: string,
  text: string,
  id?: string,
  newsletter?: Newsletter,
  template?: OpenAPI["schemas"]["NewsletterEmailTemplate"],
  transactionalEmail?: TransactionalEmail
): Promise<RenderedMarkdown> => {
  try {
    const result = await axios.post(Urls["render"](), {
      text,
      subject,
      id,
      newsletter,
      template,
      transactional_email: transactionalEmail,
    });
    return {
      email: result.data.result,
      email_free: result.data.result_free,
      web: result.data.web,
    };
  } catch (error: any) {
    return {
      email: "",
      email_free: "",
      web: "",
      error: error.response.data.message,
    };
  }
};

const asynchronouslyRenderSubPage = async (
  newsletter: Newsletter
): Promise<RenderedMarkdown> => {
  try {
    const result = await axios.post(Urls["render-subscribe-template"](), {
      newsletter,
    });
    return {
      web: result.data.result,
      email: "",
      email_free: "",
    };
  } catch (error: any) {
    return {
      web: "",
      email: "",
      email_free: "",
      error: error.response ? error.response.data.message : "Unknown error",
    };
  }
};

// https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
const VALID_HREF_REGEXES = [
  /mailto:([^]*)/,
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  /{{.*}}/,
];
const isValidHref = (url: string): boolean =>
  VALID_HREF_REGEXES.filter((r) => r.test(url)).length > 0;

/* Given a raw date timestamp, format it nicely. */
const formatDate = (date: string | Date, newsletter?: Newsletter | undefined) =>
  format(date, newsletter ? newsletter.date_format : "MM/DD/YYYY");

const formatDatetime = (
  date: string | Date,
  newsletter?: Newsletter | undefined
) =>
  format(date, newsletter ? newsletter.date_format : "MM/DD/YYYY @ HH:mm:ss");

export const formatRelativeTime = (
  date: string | Date,
  newsletter?: Newsletter
): string => {
  const SECOND = 1e3;
  const NOW = SECOND * 10;
  const MINUTE = SECOND * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;

  const parsedDate = new Date(date);

  const time = parsedDate.getTime();
  const now = Date.now();
  const delta = now - time;

  // If it's been over a week, if if the date is in the future (for some reason),
  // display an absolute date
  if (delta > WEEK || delta < 0) {
    return formatDate(parsedDate, newsletter);
  }

  // If it's happened recently, coerce to now
  if (delta < NOW) {
    return `now`;
  }

  // Get the appropriate unit and format it as such
  {
    let value: number;
    let unit: Intl.RelativeTimeFormatUnit;

    if (delta < MINUTE) {
      value = Math.floor(delta / SECOND);
      unit = "second";
    } else if (delta < HOUR) {
      value = Math.floor(delta / MINUTE);
      unit = "minute";
    } else if (delta < DAY) {
      value = Math.floor(delta / HOUR);
      unit = "hour";
    } else {
      // Use rounding, this handles the following scenario:
      // - 2024-02-13T09:00Z <- 2024-02-15T07:00Z = 2d
      value = Math.round(delta / DAY);
      unit = "day";
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "unit",
      unit: unit,
      unitDisplay: "long",
    });

    return formatter.format(value) + ` ago`;
  }
};

const renderMarkdownWithAllLinksAsTargetBlank = (markdownString: string) => {
  // Create a custom renderer to always use target=_blank.
  // Stolen from: https://github.com/chjj/marked/issues/655
  const renderer = new marked.Renderer();
  renderer.link = (href, title, text) =>
    `<a target="_blank" href="${href}" title="${title}">${text}</a>`;

  return marked(markdownString, { renderer });
};

// Given a hex string, e.g. #0069FF
const getTextColorForBackground = (hex: string) => {
  // Remove the #, leaving you with 0069FF.
  const rawHex = hex.replace("#", "");

  // Each two characters represents a color channel in the RGB space:
  // - R = 00
  // - G = 69
  // - B = FF
  const parseHex = (startPosition: number) =>
    parseInt(rawHex.substr(startPosition, 2), 16);

  // So we extract those channels.
  const [r, g, b] = [0, 2, 4].map(parseHex);

  // Convert theme using a YIQ formula:
  // https://en.wikipedia.org/wiki/YIQ#From_RGB_to_YIQ
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // YIQ space is between 0 and 255:
  // If its above average (> 128), then it's light, so we want dark text.
  // If its below average (< 128), then it's dark, so we want light text.
  return yiq >= 128 ? "black" : "white";
};

// https://stackoverflow.com/a/2117523
const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const fetchCookie = (key: string): string | null => {
  return document.cookie.split(/; */).reduce((obj, str) => {
    if (str === "") return obj;
    const eq = str.indexOf("=");
    const key = eq > 0 ? str.slice(0, eq) : str;
    let val = eq > 0 ? str.slice(eq + 1) : null;
    if (val != null)
      try {
        val = decodeURIComponent(val);
      } catch (ex) {
        /* pass */
      }
    obj[key] = val;
    return obj;
  }, {} as { [key: string]: string | null })[key];
};

const diffTwoObjects = <A>(a: A, b: A, relevantKeys: (keyof A)[]) => {
  const diff: {
    [key in keyof A]?: A[key];
  } = {};
  relevantKeys.forEach((key) => {
    if (JSON.stringify(a[key]) !== JSON.stringify(b[key])) {
      diff[key] = b[key];
    }
  });
  return diff;
};

/**
 * Checks if an array contains at least one valid non-empty string.
 * @example
 * containsValidString(["", ""]); // false
 * containsValidString([""]); // false
 * containsValidString(["", "valid"]); // true
 */
const containsValidString = (arr: string[]): boolean => {
  return arr.some(
    (answer) => typeof answer === "string" && answer.trim() !== ""
  );
};

/**
 * Removes empty values from an array of strings.
 * @example
 * removeEmptyValuesFromArray(["", "hello", "", "world"]); // Output: ["hello", "world"]
 */
const removeEmptyValuesFromArray = (arr: string[]): string[] => {
  return arr.filter((value) => value.trim() !== "");
};

const getDefaultEmailTemplate = async (
  email: Email,
  newsletter: Newsletter | null
) => {
  // If the current template is "naked", set the template to be equal to the newsletter's template or the "modern" template.
  const template = (email.template =
    email.template == "naked"
      ? newsletter?.template || "modern"
      : email.template);

  // get the current email template
  const body = (
    await asynchronouslyRenderMarkdown(
      email.body || "",
      email.subject || "",
      email.id,
      undefined,
      template
    )
  ).email;
  const parser = new DOMParser();
  const doc = parser.parseFromString(body, "text/html");
  const unsub_link = doc.querySelector(".email-actions--unsubscribe a");
  const subject_link = doc.querySelector(".subject a");

  if (unsub_link) {
    // remove the literal unsubscribe url for a specific subscriber with a template tag
    unsub_link.setAttribute("href", "{{ unsubscribe_url }}");
  }
  if (subject_link) {
    // remove the literal email archive url for a specific subscriber with a template tag
    subject_link.setAttribute("href", "{{ email_url }}");
  }
  return doc.documentElement.outerHTML;
};

export {
  asynchronouslyRenderMarkdown,
  asynchronouslyRenderSubPage,
  containsValidString,
  debounce,
  diffTwoObjects,
  fetchCookie,
  formatDate,
  formatDatetime,
  getDefaultEmailTemplate,
  getTextColorForBackground,
  isValidHref,
  notify,
  removeEmptyValuesFromArray,
  renderMarkdownWithAllLinksAsTargetBlank,
  uuid,
};
