enum LocalStorageKey {
  APP_PANELS = "appPanels",

  SHOWING_PREVIEW = "showingPreview",
  EDITOR_MODE = "editorMode",
  DRAFT_RECIPIENTS_TYPE = "draftRecipientsType",
  DRAFT_EDITOR_PANELS = "draftEditorPanels",

  HAS_SEEN_EMAILS_V2 = "hasSeenEmailsV2",

  EMAIL_ORDER = "emailOrder",
  SUBSCRIBER_ORDER = "subscriberOrder",
  EMAIL_PARAMETERS = "emailParameters",
  SUBSCRIBER_PARAMETERS = "subscriberParameters",
  ACTIVE_NEWSLETTER_ID = "activeNewsletterId",
  NEWSLETTERS_ORDER = "newslettersOrder",
  SUBSCRIBER_COLUMNS = "subscriberColumns",
  EMAIL_COLUMNS = "emailColumns",
  AUTOMATION_GROUPING = "automationGrouping",

  ANALYTICS_PERIOD = "analyticsPeriod",

  DONT_SHOW_NAKED_MODE_WARNING = "dontShowNakedModeWarning",
}

// Correspond to `EDITOR_MODE`.
export type EditorMode = "fancy" | "plaintext" | "naked" | null;

export type AutomationGrouping = "name" | "trigger";

enum DraftRecipientsType {
  DRAFT_RECIPIENTS = "draft-recipients",
  SUBSCRIBERS = "subscribers",
}

// Since the Buttondown app also powers subscriber-facing pages, we sometimes
// make requests to local storage on subscriber-facing pages are part of
// scaffolding, which fail in iframes.
const safeLocalStorageGetItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export { DraftRecipientsType, LocalStorageKey, safeLocalStorageGetItem };
