import PRICES from "@/autogen/prices.json";
import { Account } from "@/types/account";

export const FEATURE_IDS = Array.from(
  new Set(PRICES.map((feature) => feature.features).flat())
);
export type Feature = (typeof FEATURE_IDS)[number];

export type PricingTier = (typeof PRICES)[number];

const minimumPlan = (feature: Feature): PricingTier | null => {
  const tier = PRICES.find((t) => t.features.includes(feature));
  if (!tier) {
    return null;
  }
  return tier;
};

export function requiredPlan(
  account: Account,
  feature: Feature
): Pick<PricingTier, "id" | "name" | "url"> | null {
  if (account.billing_type === "GIFT") {
    return null;
  }
  if (account.billing_type === "VOLUME_BASED") {
    return null;
  }
  if (account.billing_type === "V1") {
    if (account.features.includes(feature)) {
      return null;
    }
    return {
      id: "buttondown-for-professionals",
      name: "Buttondown for Professionals",
      url: "#",
    };
  }
  if (account.billing_type === "V2") {
    if (account.features.includes(feature)) {
      return null;
    }
    const minimum = minimumPlan(feature);
    if (minimum && minimum.id === account.billing_plan) {
      return null;
    }
    return minimum;
  }
  return null;
}
